<script>
	import { fly } from 'svelte/transition';
	import { cubicOut } from 'svelte/easing';
	import { onMount } from 'svelte';

	export let formId;
	export let label;
	export let type = 'text';
	export let auto;
	export let required = false;
	export let disabled = false;
	export let value = '';
	export let invalid = required;
	export let includeValid = true;
	let focused = false;

	const handle = (e) => {
		focused = true;
		value = type.match(/^(number|range)$/) ? +e.target.value : e.target.value;
		validate();
	};

	let validate = () => {
		if (required && value.length == 0) {
			invalid = 'required';
			return false;
		}
		if (value.length >= 100) {
			invalid = 'must be less than 100 charaachters';
			return false;
		}

		if (type == 'email' && value.length >= 1) {
			let res =
				!value.match(
					/(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
				) && document.getElementById(formId) !== document.activeElement;
			console.log(res);
			if (res) {
				invalid = 'please enter a valid email';
				return false;
			}
		}
		invalid = false;
		return true;
	};

	const errorTransition = { y: -5, duration: 50, easing: cubicOut, delay: 0 };
	onMount(() => {
		validate();
	});
</script>

<div class="formItem">
	<label class="form-label" class:inputInvalid={invalid && focused} for={formId}>{label}</label>

	<input
		class:inputInvalid={invalid && focused}
		{required}
		{type}
		class="text-input"
		placeholder={label}
		autocomplete={auto}
		name={formId}
		id={formId}
		{value}
		on:input={handle}
		{disabled}
		on:blur={validate}
	/>
	{#if includeValid}
		<div class="form-error-row">
			{#if invalid && focused}
				<p class="form-error" transition:fly={errorTransition}>{invalid}</p>
			{/if}
		</div>
	{/if}
</div>

<style>
	.formItem:not(:last-child) {
		margin-right: 1em;
	}
	.formItem {
		width: 100%;
	}
	input {
		width: 100%;
	}
</style>
